import { Modal, Spin } from "ant-design-vue";

// 全局loading
export const doLoading = () => {
  const modal = Modal.info();
  modal.update({
    autoFocusButton: null,
    cancelButtonProps: { style: { display: "none" } },
    okButtonProps: { style: { display: "none" } },
    centered: true,
    closable: false,
    width: "0px",
    content: <Spin spinning />,
    icon: null,
    keyboard: false,
    mask: true,
    maskClosable: false,
    title: "",
  });
  return () => {
    modal.destroy();
  };
};

// 响应表格列宽伸缩事件(resizeColumn), 需要配置列属性 resizable: true,
export const handleResizeColumn = (w, col) => {
  col.width = w;
};

// 墨认分页配置
export const pagination = () => ({
  current: 1,
  defaultPageSize: 20,
  pageSize: 20,
  total: 0,
  showSizeChanger: true,
  hideOnSinglePage: false,
  showTotal: (total, range) =>
    `共 ${total} 条数据,当前展示 ${range[0]} - ${range[1]} 条`,
});
