import axios from "axios";
import Qs from "qs";
import { BASE_URI } from "@/config/env";
import { toLogin } from "@/router/index.js";
import { Modal } from "ant-design-vue";
import { useUserStore } from "@/store/user";
const { store, loginOut } = useUserStore();

const baseURL = BASE_URI;
const withCredentials = true;
const timeout = 500000;
const baseConfig = { baseURL, withCredentials, timeout };

// 增加令牌
const addAuthConfig = (config) => {
  const headers = config.headers || {};
  if (store.compid && store.opId) {
    config.headers = {
      ...headers,
      // ["compid"]: store.compid,
      // ["opId"]: store.opId,
    };
  }
  return config;
};

// 错误处理
const errorHandler = (err) => {
  console.log(err);
  let errInfo = { ...err };
  if (err.name === "AxiosError") {
    const { data, status: code, statusText: msg } = err.response;
    errInfo = data && data.code && data.msg ? data : { code, data, msg };
  }
  if (errInfo.code === 401) {
    loginOut().finally(() => {
      Modal.error({
        autoFocusButton: "ok",
        cancelButtonProps: { display: "none" },
        title: errInfo.msg || "登录失效",
        content: "请重新登录",
        okText: "重新登录",
        onOk: toLogin,
        onCancel: toLogin,
      });
    });
  }
  return Promise.reject(errInfo);
};

// 正常处理
const responseHandler = (response) => {
  const res = response.data;
  const code = res.code;
  if (code === 200 || code === 0) {
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};

// 表单请求
const service = axios.create(baseConfig);
service.interceptors.request.use((config) => {
  config.headers["Content-Type"] =
    "application/x-www-form-urlencoded;charset=UTF-8";
  config.transformRequest = Qs.stringify; // 在请求之前对data传参进行格式转换
  return addAuthConfig(config);
}, errorHandler);
service.interceptors.response.use(responseHandler, errorHandler);

/**
 * get表单
 * @param {*} url
 * @param {*} params
 * @returns
 */
function doGet(url, params) {
  return service.get(url, { params });
}

/**
 * post表单提交
 * @param {*} url
 * @param {*} params
 * @returns
 */
function formPost(url, params) {
  return service.post(url, params);
}

/**
 * json请求
 */
const jsonService = axios.create(baseConfig);
jsonService.interceptors.request.use((config) => {
  config.headers["Content-Type"] = "application/json;charset=UTF-8";
  return addAuthConfig(config);
}, errorHandler);
jsonService.interceptors.response.use(responseHandler, errorHandler);

/**
 *
 * post提交json
 */
function jsonPost(url, params) {
  return jsonService.post(url, params);
}

/**
 * 上传请求
 */
const uploadService = axios.create(baseConfig);
uploadService.interceptors.request.use((config) => {
  config.headers["Content-Type"] = "multipart/form-data;charset=UTF-8";
  return addAuthConfig(config);
}, errorHandler);
uploadService.interceptors.response.use(responseHandler, errorHandler);

/**
 * 上传文件
 */
function uploadPost(url, params) {
  return uploadService.post(url, params);
}

/**
 * 免授权下载
 * @param {*} url
 * @param {*} data
 */
function getFile(url, data, filename) {
  const windowUrl = `${baseURL}${url}?${Qs.stringify(data)}`;
  // window.open(windowUrl);
  let downloadElement = document.createElement("a");
  downloadElement.href = windowUrl;
  if (filename) {
    downloadElement.download = filename; // 下载后文件名
  }
  document.body.appendChild(downloadElement);
  downloadElement.click(); // 点击下载
  document.body.removeChild(downloadElement); // 下载完成移除元素
}

/**
 * 处理下载
 * @param resp
 */
const processDownload = (resp) => {
  if (resp.status !== 200 || !resp.headers["content-disposition"]) {
    // 找不到文件名--不可下载
    return Promise.reject({ code: resp.status, msg: resp.statusText });
  } else {
    const matchFilename =
      resp.headers["content-disposition"].match(/filename=[^;]*/);
    let filename = null;
    if (matchFilename && matchFilename[0])
      filename = matchFilename[0].split("=")
        ? matchFilename[0].split("=")[1]
        : "";
    const blob = new Blob([resp.data], { type: resp.headers["content-type"] });
    let downloadElement = document.createElement("a");
    const href = window.URL.createObjectURL(blob); // 创建下载的链接
    downloadElement.href = href;
    // 转成中文
    filename = decodeURI(filename);
    downloadElement.download = filename; // 下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click(); // 点击下载
    document.body.removeChild(downloadElement); // 下载完成移除元素
    window.URL.revokeObjectURL(href); // 释放掉blob对象
  }

  return Promise.resolve({ code: resp.status, msg: resp.statusText });
};

/**
 * 下载请求
 */
const downloadFormService = axios.create(baseConfig);
downloadFormService.interceptors.request.use((config) => {
  config.transformRequest = Qs.stringify; // 在请求之前对data传参进行格式转换
  config.responseType = "blob";
  return addAuthConfig(config);
}, errorHandler);
downloadFormService.interceptors.response.use(processDownload, errorHandler);

function downloadGet(url, params) {
  return downloadFormService.get(url, params);
}

function downloadPost(url, data) {
  return downloadFormService.post(url, data);
}

const downloadJsonService = axios.create(baseConfig);
downloadFormService.interceptors.request.use((config) => {
  config.responseType = "blob";
  return addAuthConfig(config);
}, errorHandler);
downloadFormService.interceptors.response.use(processDownload, errorHandler);

function downloadJsonPost(url, data) {
  return downloadJsonService.post(url, data);
}

export default {
  doGet,
  formPost,
  jsonPost,
  uploadPost,
  getFile,
  downloadGet,
  downloadPost,
  downloadJsonPost,
};
