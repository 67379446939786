import request from "./request";
import handler from "./handler";

export const HANDLE = handler;

// 厂家管理5
export const producerAPI = {
  // GET厂家编辑
  edit(data) {
    return request.doGet("/producer/edit", data);
  },
  // GET厂家列表
  list(data) {
    return request.doGet("/producer/list", data);
  },
  // GET修改状态
  modifyStatus(data) {
    return request.doGet("/producer/modifyStatus", data);
  },
  // POST厂家新增
  save(data) {
    return request.jsonPost("/producer/save", data);
  },
  // GET选择厂家列表
  selProducerList(data) {
    return request.doGet("/producer/selProducerList", data);
  },
};

// 设备管理8
export const equipAPI = {
  // GET设备编辑
  edit(data) {
    return request.doGet("/equip/edit", data);
  },
  // GET设备在线状态列表
  equipList(data) {
    return request.doGet("/equip/equipList", data);
  },
  // GET设备管理列表
  list(data) {
    return request.doGet("/equip/list", data);
  },
  // GET修改状态
  modifyStatus(data) {
    return request.doGet("/equip/modifyStatus", data);
  },
  // GET操作日志列表
  operateList(data) {
    return request.doGet("/equip/operateList", data);
  },
  // GET罐列表
  potList(data) {
    return request.doGet("/equip/potList", data);
  },
  // POST设备新增
  save(data) {
    return request.jsonPost("/equip/save", data);
  },
  // GET车辆列表
  selVehicelList(data) {
    return request.doGet("/equip/selVehicelList", data);
  },
  // POST摄像头操作
  cameraOperate(data) {
    return request.jsonPost("/cameraOperate", data);
  },
  // POST加剂操作
  equipOperate(data) {
    return request.jsonPost("/equipOperate", data);
  },
  // GET执行操作
  executeCommond(data) {
    return request.doGet("/executeCommond", data);
  },
  // GET获取直播流
  liveStream(data) {
    return request.doGet("/liveStream", data);
  },
  // GET作废记录
  delOperateRecord(data) {
    return request.doGet("/equip/delOperateRecord", data);
  },
  // GET设备实时状态
  realStatus(data) {
    return request.doGet("/equip/realStatus", data);
  },
};
