import { message } from "ant-design-vue";
import { doLoading } from "@/utils";

// 常用Handler
export default {
  handleError(error) {
    message.error(`请求失败(${error.code || ""}):${error.msg || "无"}`);
  },
  handleErrorTag(tag) {
    return (error) => {
      message.error(
        `${tag || "请求失败"}(${error.code || ""}):${error.msg || "无"}`
      );
    };
  },
  handleErrorMessage(msg) {
    return () => {
      message.error(msg);
    };
  },
  handleSuccess(res) {
    message.success("操作成功");
    return res;
  },
  handlerSuccessMessage(msg) {
    return (res) => {
      message.success(msg);
      return res;
    };
  },
  fullLoading(p) {
    const close = doLoading();
    return p.finally(() => {
      close();
    });
  },
  doLoading,
  message,
};
